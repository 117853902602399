import styled from 'styled-components';

import bgglass from '../../assets/LandignPage/Home/bg-glass.svg'

export const Container = styled.div.attrs({

})`
    margin:30px;
    padding:10px;
    text-align:center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    width:1538px;
    background: radial-gradient(34.04% 62.51% at 75.56% 74.06%, rgba(217, 23, 139, 0.2) 0%, rgba(105, 20, 214, 0) 100%), radial-gradient(38.27% 64.55% at 73.34% 17.27%, rgba(15, 29, 162, 0.18) 0%, rgba(35, 16, 152, 0) 100%), radial-gradient(48.44% 91.97% at 25.98% 77.62%, rgba(203, 32, 196, 0.153) 0%, rgba(203, 32, 142, 0) 100%), radial-gradient(36.2% 70.33% at 13.55% 13.36%, rgba(47, 20, 210, 0.2) 0%, rgba(102, 20, 210, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01)), #000000;
    border-radius: 24px;
    border-color:#D1C0E8;
    border:solid;
    border-width:1px;

    @media(max-width:500px){
      /* background: radial-gradient(24.48% 93.25% at 85.76% 5.16%, rgba(23, 15, 112, 0.4) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(44.76% 196.25% at 75.06% 105.47%, rgba(87, 18, 99, 0.5) 0%, rgba(29, 5, 33, 0) 100%), radial-gradient(40.82% 128.91% at 52.52% 11.87%, rgba(37, 9, 72, 0.8) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(28.36% 126.68% at 39.1% 92.5%, rgba(58, 5, 43, 0.9) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(28.7% 127.11% at 23.53% 14.06%, #3C0E41 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(37.45% 122.41% at 7.1% 77.34%, #1C0835 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, rgba(255, 255, 255, 0.05) -1.07%, rgba(0, 0, 0, 0) 97.69%), #000000; */
      background: radial-gradient(24.48% 93.25% at 85.76% 5.16%, rgba(23, 15, 112, 0.2) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(44.76% 196.25% at 75.06% 105.47%, rgba(87, 18, 99, 0.25) 0%, rgba(29, 5, 33, 0) 100%), radial-gradient(40.82% 128.91% at 52.52% 11.87%, rgba(37, 9, 72, 0.4) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(28.36% 126.68% at 39.1% 92.5%, rgba(58, 5, 43, 0.45) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(28.7% 127.11% at 23.53% 14.06%, rgba(60, 14, 65, 0.5) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(37.45% 122.41% at 7.1% 77.34%, rgba(28, 8, 53, 0.5) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(90deg, rgba(255, 255, 255, 0.05) -1.07%, rgba(0, 0, 0, 0) 97.69%), #000000;
      /* margin-bottom:5px; */
      border-width:0px;
      margin: 0px;
      margin-top:60px;
      border-radius:0px;        /* padding:5px; */
  }

  @media(max-width:730px){
            margin-bottom:15%;
  }
    
`;

export const DivPricipalBody = styled.div`
        @media(max-width:500px){
        /* font-size: 12px; */
        /* padding:5px; */
  }

`;


export const TextoTitle = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 40px 0px;
    color: #FFFFFF;
    @media(max-width:500px){
        font-size: 12px;
        /* padding:5px; */
  }
`;

export const TextoInfo = styled.p`
    font-size: 56px;
    line-height: 64px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 16px 16%;
    background: -webkit-linear-gradient(right,#CB208E, #6614D2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media(max-width:500px){
        font-size: 24px;
        margin-right: 18px;
        margin-left: 18px;
        line-height: 26px;
        /* padding:5px; */
  }

`;

export const TextoDesc = styled.p`
    font-family: GothamBook;
    font-style: Book;
    font-size: 16px;
    line-height: 24px;
    margin: 32px 0px;
    text-align: center;
    color: #BDBDBD;
    @media(max-width:500px){
        font-size: 12px;
        margin-right: 18px;
        margin-left: 18px;
        line-height: 20px;
        /* padding:5px; */
  }

`;

export const Link = styled.a`
    min-width:300px;
    max-width:100%;

`;

export const Colorida = styled.div`
  text-align:center;
  margin-top:3px;
  background: -webkit-linear-gradient(right,#CB208E, #6614D2);
  height:5px;
  margin-left:25px;
  margin-right:25px;
  /* width:100%; */
`;


export const Button = styled.button`
    background: radial-gradient(16.56% 84.78% at 65.43% 80.43%, rgba(138, 21, 167, 0.2) 0%, rgba(138, 21, 167, 0.048) 100%), radial-gradient(12.35% 66.3% at 36.13% 10.87%, rgba(102, 20, 210, 0.24) 0%, rgba(102, 20, 210, 0.0096) 100%), radial-gradient(19.53% 119.05% at 13.67% 31.52%, rgba(203, 32, 142, 0.2) 0%, rgba(203, 32, 142, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    border: 0;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    /* background: #21192E; */
    
    height: 44px;
    /* padding-top:14px; */
    /* padding-bottom:14px; */
    padding-right:10px;
    padding-left:10px;
    color: #FFF;
    margin: 0 0 10px;
    font-size:14px;
    /* width: 100%; */
    color:#FFFFFF;
    &:hover {
      border-style: solid;
      border-color:#6614D2;
      border-width:1px;
  }


    @media(max-width:500px){
        /* margin-bottom:60px;
        width: 100%;
        padding: 14px  5px; */
        /* padding:5px; */
  }
  
`;

export const TextButton = styled.p`
    margin:8px;
`;

