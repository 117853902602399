import styled from 'styled-components'

import imgBack from '../../assets/LandignPage/Home/bg-mesha.png'

export const Container = styled.div`
    background:url(${imgBack});
    background-repeat: no-repeat;
    /* min-height: 100vh; */
    width: 100%;
    height: 100%;
    max-width:1600px;
    background-size:100%;
    @media(max-width:500px){
    }
`;

export const HeaderDiv = styled.div`
    margin-top:14%;
    text-align:center;
`;

export const TextoTitle = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top:40px;
    color: #FFFFFF;
    @media(max-width:500px){
    font-size: 16px;
    margin-right: 18px;
    margin-left: 18px;
    line-height: 26px;
  }
`;

export const TextoInfo = styled.p`
    
    font-size: 56px;
    line-height: 64px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 16px;
    background: -webkit-linear-gradient(right,#CB208E, #6614D2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media(max-width:500px){
    font-size: 32px;
    margin-right: 18px;
    margin-left: 18px;
    line-height: 32px;
  }

`;

export const TextoDesc = styled.p`
    font-size: 16px;
    line-height: 24px;
    margin-bottom:32px;
    text-align: center;
    color: #BDBDBD;
    @media(max-width:500px){
    margin-right: 18px;
    margin-left: 18px;
    }

`;


export const Button = styled.button`
    background: radial-gradient(16.56% 84.78% at 65.43% 80.43%, rgba(138, 21, 167, 0.2) 0%, rgba(138, 21, 167, 0.048) 100%), radial-gradient(12.35% 66.3% at 36.13% 10.87%, rgba(102, 20, 210, 0.24) 0%, rgba(102, 20, 210, 0.0096) 100%), radial-gradient(19.53% 119.05% at 13.67% 31.52%, rgba(203, 32, 142, 0.2) 0%, rgba(203, 32, 142, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    border: 0;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color:#FFFFFF;
    padding: 14px 32px;
    &:hover {
      border-style: solid;
      border-color:#6614D2;
      border-width:1px;
  }
`;


export const Arrow = styled.div`
    padding:10px;
    width:100%;
    margin-top:5%;
    text-align:center;
`;