import styled from 'styled-components';
import Mmesha from '../../assets/LandignPage/Home/m-mesha.svg'

import bgglass from '../../assets/LandignPage/Home/bg-glass.svg'

export const Container = styled.div.attrs({

})`
    text-align:center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(34.04% 62.51% at 75.56% 74.06%, rgba(217, 23, 139, 0.2) 0%, rgba(105, 20, 214, 0) 100%), radial-gradient(38.27% 64.55% at 73.34% 17.27%, rgba(15, 29, 162, 0.18) 0%, rgba(35, 16, 152, 0) 100%), radial-gradient(48.44% 91.97% at 25.98% 77.62%, rgba(203, 32, 196, 0.153) 0%, rgba(203, 32, 142, 0) 100%), radial-gradient(36.2% 70.33% at 13.55% 13.36%, rgba(47, 20, 210, 0.2) 0%, rgba(102, 20, 210, 0) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.02) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01)), #000000;
    
    @media(max-width:670px){
    .divForm {
        width: 100%;
        height:100v;
        /* right: 0% */
    }
  }
    
`;


export const PopUp = styled.div`
    position: absolute;
    top: -1%;
    margin-top: 15%;
    width:100vw;
    text-align: initial;

`;