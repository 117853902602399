import React from 'react';

import { useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'

import Calendar from '../../assets/LandignPage/Iconly/Calendar.png'
import Edit from '../../assets/LandignPage/Iconly/Edit.png'
import Setting from '../../assets/LandignPage/Iconly/Setting.png'

import {
  Container,
  DivPricipalBody,
  TextoInfo,
  TextoDesc,
  Button,
  TextButton
} from './styles';

import './styles.css'
import '../../styles/GlobalStyle.css'


function Body() {

  let history = useHistory();

  function handleClick() {
    history.push("/form");
  }
  return (

    <Container>
      <DivPricipalBody >
        {/* <TextoTitle className="font-body-TextoTitle">
          Preocupado com os custos de tecnologia?
        </TextoTitle> */}
        <TextoInfo className="font-body-TextoInfo">
          {/* Decida como utilizar cada recurso */}
          Preocupado com os custos de tecnologia?
        </TextoInfo>
        <TextoDesc className="font-body-TextoDesc">
          Otimize seus custos em TI com um Squad dedicado a gerar resultados reais para o seu negócio.
      </TextoDesc>
        <Row>
          <Col className="divCol">
            <Image src={Calendar} />
            <TextoDesc className="font-body-col-TextoDesc" >
              Tenha um Product Manager focado em desenvolver, aperfeiçoar e otimizar seu produto para o melhor resultado.
          </TextoDesc>
            <Button className='font-Button' onClick={handleClick}>
              
              Preciso de Product Manager
            {/* <Colorida /> */}
            </Button>
          </Col>
          <Col className="divCol">
            <Image src={Edit} />
            <TextoDesc className="font-body-col-TextoDesc">
              É o especialista que garante soluções criativas, duradouras e alinhadas às necessidades do seu usuário.
          </TextoDesc>
          <Button className='font-Button' onClick={handleClick}>
              Necessito de UI/UX
              {/* <Colorida /> */}
            </Button>
          </Col>
          <Col className="divCol">
            <Image src={Setting} />
            <TextoDesc className="font-body-col-TextoDesc">
              Temos desenvolvedores experientes e que trabalham com as mais poderosas tecnologias existentes no mercado.
          </TextoDesc>
          <Button className='font-Button' onClick={handleClick}>
              Quero contratar Dev’s
              {/* <Colorida /> */}
              {/* href={`${process.env.REACT_APP_HOST_APP}/#/form`} */}
            </Button>
          </Col>
        </Row>
      </DivPricipalBody>
    </Container>
  )
}

export default Body;