import React from 'react';
import Routes from './routes';
import './styles/GlobalStyle.css';
import Index from './views/Index';

// import { Container } from './styles';

function App() {
  return (
    <>
      
      <Routes/>
    </>
  )
}

export default App;