import React, { useState, useEffect } from 'react';
import {useHistory,Redirect} from 'react-router-dom'
import { Col, Form, ProgressBar, Row, Button } from 'react-bootstrap';

import { Container } from './styles';
import './styles.css'

function Teste() {
    const [progress, setProgress] = useState(0)
    const [isVisivle, setIsVisible] = useState(false)
    const [page, setPage] = useState(0)
    const [nome, setNome] = useState('')
    const [cargo, setCargo] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [email, setEmail] = useState('')
    const [demanda, setDemanda] = useState('')

    const url = "http://localhost:3000/teste/"
    const history = useHistory()

    useState(() => {
        if(progress ===20){

            <Redirect to="/teste#page-1" />
             
        }else if (progress ===40){
            next2()
        }
        
    }, [progress,page])

    const onInputCharge = (event) => {
        // event.preventdefault()
        setProgress(progress + 20)
        setIsVisible(false)
        setPage(page+1)
        next1()
    }

    function next1(){
        history.push(`#page-`+1)
    }
    function next2(){
        history.push(`#page-`+2)
    }

    function next3(){
        history.push(`#page-`+1)
    }

    const setVisible = () => {
        setIsVisible(true)
    }

    return (
        <Container>
            <nav>
  <a href="#page-0">1</a>
  <a href="#page-1">2</a>
  <a href="#page-2">3</a>
</nav>


            <div className="teste">
                <Form>
                    <scroll-container>
                        <Form.Group id="page-0" controlId="formGroupEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control onChange={setVisible} type="email" placeholder="Enter email" />
                            {isVisivle && (
                                <Button variant="warning"
                                    onClick={onInputCharge}

                                >Warning
                                </Button>
                            )}

                        </Form.Group>
                        <scroll-page id="page-1">
                        <Form.Group id="page-1" controlId="formGroupPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                        </scroll-page>
                        <scroll-page id="page-2">
                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" defaultValue="Choose...">
                                <option>Teste 1</option>
                                <option>Teste 2</option>
                            </Form.Control>
                        </Form.Group>
                        </scroll-page>
                    </scroll-container>
                </Form>
            </div>
            <Button 
            >+</Button>
            <ProgressBar className="vaiQue" animated now={20} />
        </Container>
    )
}

export default Teste;