import styled from 'styled-components'

import imgBack from '../../assets/LandignPage/Home/bg-mesha.png'

export const Container = styled.div`
    text-align:center;
    align-items:center;
    justify-content:center;
    /* width:100vw; */
    height:100vh;
    /* margin:30px; */
    padding:10px;
    /* background: radial-gradient(50% 50% at 50% 50%, #1C0837 0%, rgba(0, 0, 0, 0.88) 100%); */
    @media(max-width:500px){
        margin-top:0px;
        /* padding:5px; */
  }
`;

