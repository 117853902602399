import React from 'react';
import { useHistory } from 'react-router-dom';

import Image from 'react-bootstrap/Image'

import logo from '../../assets/LandignPage/logos/logo-mesha.svg'
import arrow from '../../assets/LandignPage/Home/arrow.svg'

import {
  Container,
  HeaderDiv,
  TextoTitle,
  TextoInfo,
  TextoDesc,
  Button,
  Arrow,

} from './styles';

import '../../styles/GlobalStyle.css'

function Header() {
  let history = useHistory();

  function handleClick() {
    history.push("/form");
  }

  return (
    <Container>
      <HeaderDiv>
        <Image src={logo} />
        <TextoTitle className='font-header-TextoTitle'>
          DIFICULDADE EM CONTRATAR?
        </TextoTitle>
        <TextoInfo className='font-header-TextoInfo'>
          Contrate um time dedicado de TI 
          </TextoInfo>
        <TextoDesc className="font-header-TextoDesc">
        Escale um time com especialistas de alta performance para potencializar os resultados da sua empresa. 
          {/* exclusivamente no seu projeto de forma remota, segura e pelo tempo que você quiser. */}
        </TextoDesc>
        <Button onClick={handleClick} className="font-Button">
          Quero escalar meu time
          </Button>
      </HeaderDiv>
      <Arrow>
        <Image src={arrow} />
      </Arrow>
    </Container>
  );
}

export default Header;