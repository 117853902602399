import React from 'react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Body from '../../components/Body';
import Footer from '../../components/Footer';

import Header from '../../components/Header'

import './styles.css'

function Index() {
    return (
        <Container fluid className="fullContainer">
            <Row className="justify-content-md-center">
                <Header/>
            </Row>
            <Row className="justify-content-md-center">
                <Body/>
            </Row>
            <Row className="justify-content-md-center">
                <Footer/>
            </Row>
        </Container>
    )
}

export default Index;