import React from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Image } from 'react-bootstrap';

import r2 from '../../assets/LandignPage/logos/Home/R2.svg'
import express from '../../assets/LandignPage/logos/Home/express.svg'
import uppo from '../../assets/LandignPage/logos/Home/uppo.svg'
import kenoa from '../../assets/LandignPage/logos/Home/kenoa.svg'

import {
  Container,
  Button,
  TextoInfo,
  RowFotter,
  Copyright
} from './styles';

import './styles.css'

import '../../styles/GlobalStyle.css'

function Footer() {

  let history = useHistory();

  function handleClick() {
    history.push("/form");
  }

  return (
    <Container>
      <RowFotter>
        <Row>
          <Col>
            <TextoInfo className="font-footer-TextoInfo headerFooter">
              Ajudamos empresas a <br /> crescer com Squads
            </TextoInfo>
          </Col>
        </Row>
        <Row>
          <Col className="colFooter">
            <Image src={r2} />
          </Col>
          <Col className="colFooter">
            <Image src={express} />
          </Col>
          <Col className="colFooter">
            <Image src={uppo} />
          </Col>
          <Col className="colFooter">
            <Image src={kenoa} />
          </Col>
        </Row>
        <Button onClick={handleClick} className="font-Button">
          Também quero inovar
      </Button>

        <Copyright className="font-footer-Copyright">
          Copyright © 2021 Mesha Technology - Todos os direitos reservados
          </Copyright>
      </RowFotter>

    </Container>
  )
}

export default Footer;