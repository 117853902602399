import styled from 'styled-components';

import imageTeste from '../../assets/LandignPage/Home/bg-mesha.png'

export const Container = styled.div`

`;


export const Form = styled.div`

`;
export const PopUp = styled.div`
    position: absolute;
    top: -1%;
    margin-top: 15%;
    width:100vw;
    text-align: initial;

`;

