import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import Mmesha from '../../assets/LandignPage/Home/m-mesha.svg'
import logo from '../../assets/LandignPage/logos/logo-mesha.svg'

import { PopUp } from './styles';
import { TextoInfo, TextoDesc } from '../../components/Body/styles'
import './styles.css';

function Form() {

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showButton, setShowButton] = useState(false)
  let history = useHistory();

  const onSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    console.log(data)

    fetch('https://somosmesha.activehosted.com/proc.php', {
      method: 'POST',
      body: data,
      mode: 'no-cors',
    })
      .then(response => {
        setFormSubmitted(true)
        setTimeout(() => {

          setFormSubmitted(false)
          history.push("/");
        }, 5000);
      })
      .catch(error => console.log('Request failed', error));
  }

  const onShowButton = () => {
    setShowButton(true)
  }

  return (
    <div className="container">
      {/* <Image src={Mmesha} className="imagemFundo imageForm" /> */}
      {/* {!formSubmitted&&(
      <ProgressBar className="progressive" animated now={20} />
      )} */}
      { formSubmitted && (

        <PopUp>
          <TextoInfo>
            Tudo certo por aqui!
          </TextoInfo>
          <TextoDesc className="">
            Nossa equipe entrará em contato com você.
          </TextoDesc>
        </PopUp>
      )}

      { !formSubmitted && (
        <div className="teste">
          <form onSubmit={onSubmit} className="divForm">

            <input type="hidden" name="u" value="1" />
            <input type="hidden" name="f" value="1" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <div class="_form-content">
              <div class="_form_element _x96462518 _full_width " >
                <label for="fullname" className="text" >
                  Como podemos <br/> te chamar?
                            </label>
                <div class="campo">
                  <input type="text" className="input" onChange={onShowButton} id="fullname" name="fullname" placeholder="Digite seu nome" required />
                  {/* {showButton && (
                    <Button id="_form_1_submit" className="buttonForm btrigth" class="_submit" type="">
                      OK
                    </Button>
                  )} */}
                </div>
              </div>
              <div class="_form_element _field1 _full_width " >
                <label for="field[1]" className="text">
                  Qual o seu <br/>cargo?
              </label>
                <div class="_field-wrapper">
                  <input type="text" className="input" id="field[1]" name="field[1]" placeholder="Digite seu cargo" required />
                  {/* <Button id="_form_1_submit" className="buttonForm btrigth" class="_submit" type="submit">
                    OK
                </Button> */}
                </div>
              </div>
              <div class="_form_element _x58988440 _full_width " >
                <label for="customer_account" className="text" >
                  Qual o nome da <br/> sua empresa?
              </label>
                <div class="_field-wrapper">
                  <input type="text" className="input" id="customer_account" name="customer_account" placeholder="Digite sua conta" required />
                  {/* <Button id="_form_1_submit" className="buttonForm btrigth" class="_submit" type="submit">
                    OK
                </Button> */}
                </div>
              </div>
              <div class="_form_element _x51042584 _full_width " >
                <label for="email" className="text">
                  Qual o seu <br/> Email?
              </label>
                <div class="_field-wrapper">
                  <input type="text" className="input" id="email" name="email" placeholder="Digite seu e-mail" required />
                  {/* <Button id="_form_1_submit" className="buttonForm btrigth" class="_submit" type="submit">
                    OK
                </Button> */}
                </div>
              </div>
              <div class="_form_element _field3 _full_width " >
                <label for="field[3]" className="text"  >
                  Qual a sua <br/> demanda?
              </label>
                <div class="_field-wrapper">
                  <select name="field[3]" className="input" id="field[3]" required>
                    <option selected>
                    </option>
                    <option value="Product Manager" >
                      Product Manager
                  </option>
                    <option value="UI/UX Designer" >
                      UI/UX Designer
                  </option>
                    <option value="Desenvolvedor" >
                      Desenvolvedor
                  </option>
                  </select>
                </div>
              </div>
              <div class="_button-wrapper _full_width" >
                <Button id="_form_1_submit" className="buttonForm" class="_submit" style={{marginBottom:'60px'}}type="submit">
                  Enviar
              </Button>
              </div>
            </div>
          </form>
        </div>
      )}


      <Image src={logo} className="logoForm" />

    </div>
  )
}

export default Form;