import styled from 'styled-components';
import Mmesha from '../../assets/LandignPage/Home/m-mesha.svg'

import bgglass from '../../assets/LandignPage/Home/bg-glass.svg'

export const Container = styled.div.attrs({

})`
    text-align:center;
    align-items:center;
    justify-content:center;
    flex-direction: row;
    display: flex;
    width:100vw;
    max-width:1600px;
    height:80vh;
    max-height:800px;
    margin:30px;
    padding:10px;
    background: radial-gradient(50% 50% at 50% 50%, #1C0837 0%, rgba(0, 0, 0, 0.88) 100%);
    @media(max-width:500px){
        margin-top:30%;
        /* padding:5px; */
  }
`;

export const Button = styled.button`
    background: radial-gradient(16.56% 84.78% at 65.43% 80.43%, rgba(138, 21, 167, 0.2) 0%, rgba(138, 21, 167, 0.048) 100%), radial-gradient(12.35% 66.3% at 36.13% 10.87%, rgba(102, 20, 210, 0.24) 0%, rgba(102, 20, 210, 0.0096) 100%), radial-gradient(19.53% 119.05% at 13.67% 31.52%, rgba(203, 32, 142, 0.2) 0%, rgba(203, 32, 142, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    border: 0;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-top:72px;
    margin-bottom:90px;
    color:#FFFFFF;
    padding: 14px 32px;
    @media(max-width:500px){
        margin-bottom:60px;
        width: 100%;
        padding: 14px  5px;
        /* padding:5px; */
  }
  &:hover {
      border-style: solid;
      border-color:#6614D2;
      border-width:1px;
  }
  
`;

export const TextoInfo = styled.p`
    font-size: 56px;
    line-height: 64px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 16px 0px;
    margin-bottom:72px;
    width:100%;
    background: -webkit-linear-gradient(right,#CB208E, #6614D2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media(max-width:500px){

        font-size: 24px;
        margin-right: 18px;
        /* margin-left: 18px; */
        line-height: 32px;
        margin-top: 50%;
        /* padding:5px; */
  }

  @media(max-width:332){
    margin-top: 70px;
  }
`;

export const RowFotter = styled.div`
    /* margin:50px; */

`;

export const Copyright = styled.p`
    font-size: 16px;
    align-items: center;
    letter-spacing: 0.1em;
    opacity: 0.5;
    color:#ffffff;



`;