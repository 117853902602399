import React from 'react'
import {HashRouter, Switch, Route} from 'react-router-dom'
import Index from './views/Index'
import Form from './views/Form'
import Teste from './views/Teste'
import TesteForm from './views/TesteForm'


export default function Routes () {
    return(
    <HashRouter>
        <Switch>
            <Route path="/" exact component={Index}/>
            {/* <Route path="/form" component={Form}/> */}
            {/* <Route path="/teste" component={Teste}/> */}
            <Route path="/form" component={TesteForm}/>
        </Switch>
    </HashRouter>
    )
}