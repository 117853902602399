import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import logo from '../../assets/LandignPage/logos/logo-mesha.svg'
import { TextoInfo, TextoDesc } from '../../components/Body/styles'
import { PopUp } from './styles';
import './styles.css'

function TesteForm() {

    const [progress, setProgress] = useState(0)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const [showButtonCargo, setShowButtonCargo] = useState(false)
    const [showButtonEmpresa, setShowButtonEmpresa] = useState(false)
    const [showButtonEmail, setShowButtonEmail] = useState(false)
    const [showButtonEnviar, setShowButtonEnviar] = useState(false)
    let history = useHistory();

    const onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data)

        fetch('https://somosmesha.activehosted.com/proc.php', {
            method: 'POST',
            body: data,
            mode: 'no-cors',
        })
            .then(response => {
                setFormSubmitted(true)
                setTimeout(() => {

                    setFormSubmitted(false)
                    history.push("/");
                }, 5000);
            })
            .catch(error => console.log('Request failed', error));
    }


    const click = () => {
        setShowButton(false)
        document.getElementById('#cargo').focus();
        setProgress(20);
    }
    const click1 = () => {
        setShowButtonCargo(false)
        document.getElementById('#empresa').focus();
        setProgress(40);
    }
    const click2 = () => {
        setShowButtonEmpresa(false)
        document.getElementById('#email').focus();
        setProgress(60);
    }
    const click3 = () => {
        setShowButtonEmail(false)
        document.getElementById('#demanda').focus();
        setProgress(80);
    }


    const onShowButton = () => {
        setShowButton(true)
    }
    const onShowButtonCargo = () => {
        setShowButtonCargo(true)
    }
    const onShowButtonEmpresa = () => {
        setShowButtonEmpresa(true)
    }
    const onShowButtonEmail = () => {
        setShowButtonEmail(true)
    }
    const onShowButtonEnviar = () => {
        setShowButtonEnviar(true)
    }



    {/* Ate ake ta de boa */}
    return (
        <Container className="containerForm">

            <ProgressBar className="testeProgress" animated now={progress} />

            <Col>
                <Row className="rowFormTeste">
                    <Col className="colTestForm">
                        {formSubmitted && (

                            <PopUp>
                                <TextoInfo>
                                    Tudo certo por aqui!
                            </TextoInfo>
                                <TextoDesc className="">
                                    Nossa equipe entrará em contato com você.
                            </TextoDesc>
                            </PopUp>
                        )}

                        {!formSubmitted && (

                            <Form onSubmit={onSubmit} className="formP">

                                <input type="hidden" name="u" value="1" />
                                <input type="hidden" name="f" value="1" />
                                <input type="hidden" name="s" />
                                <input type="hidden" name="c" value="0" />
                                <input type="hidden" name="m" value="0" />
                                <input type="hidden" name="act" value="sub" />
                                <input type="hidden" name="v" value="2" />
                                <div class="_form-content">


                                    <Form.Group class="_form_element _field1 _full_width " className="geral" >
                                        <Form.Label for="field[1]" className="text">
                                            Como podemos te chamar ?
                                        </Form.Label>
                                        <Row className="campo">
                                            <Col>
                                                <Form.Control type="text" autoComplete="off" className="input" onChange={onShowButton} id="#name" name="field[1]" required />
                                            </Col>
                                            {showButton && (
                                                <Button id="_form_1_submit" onClick={click} className="buttonForm btrigth" class="_submit" type="submit">
                                                    OK
                                                </Button>
                                            )}
                                        </Row>
                                    </Form.Group>



                                    <Form.Group class="_form_element _field1 _full_width " className="geral" >
                                        <Form.Label for="field[1]" className="text">
                                            Qual o seu cargo?
                                        </Form.Label>
                                        <Row className="campo">
                                            <Col>
                                                <Form.Control type="text" className="input" autoComplete="off" onChange={onShowButtonCargo} id="#cargo" name="field[1]" required />
                                            </Col>
                                            {showButtonCargo && (
                                                <Button id="_form_1_submit" onClick={click1} className="buttonForm btrigth" class="_submit" type="submit">
                                                    OK
                                                </Button>
                                            )}
                                        </Row>
                                    </Form.Group>




                                    <Form.Group class="_form_element _x58988440 _full_width " className="geral" >
                                        <Form.Label for="customer_account" className="text" >
                                            Qual o nome da sua empresa?
                                        </Form.Label>
                                        <Row className="campo">
                                            <Col>
                                                <Form.Control type="text" className="input" autoComplete="off" onChange={onShowButtonEmpresa} id="#empresa" name="customer_account" required />
                                            </Col>
                                            {showButtonEmpresa && (

                                                <Button id="_form_1_submit" onClick={click2} className="buttonForm btrigth" class="_submit" type="submit">
                                                    OK
                                                </Button>
                                            )}
                                        </Row>
                                    </Form.Group>




                                    <Form.Group class="_form_element _x51042584 _full_width " className="geral" >
                                        <Form.Label for="email" className="text">
                                            Qual o seu Email?
                                        </Form.Label>
                                        <Row className="campo">
                                            <Col>
                                                <Form.Control type="text" className="input" autoComplete="off" onChange={onShowButtonEmail} id="#email" name="email" required />
                                            </Col>
                                            {showButtonEmail && (

                                                <Button id="_form_1_submit" onClick={click3} className="buttonForm btrigth" class="_submit" type="submit">
                                                    OK
                                                </Button>
                                            )}
                                        </Row>
                                    </Form.Group>




                                    <Form.Group class="_form_element _field3 _full_width " className="geral" >
                                        <Form.Label for="field[3]" id="#demanda" className="text"  >
                                            Qual a sua demanda?
                                        </Form.Label>
                                        <Row className="campo">
                                            <Col>
                                                <select name="field[3]" onChange={onShowButtonEnviar} className="input" id="field[3]" required>
                                                    <option selected>
                                                        Selecione ...
                                                    </option>
                                                    <option value="Product Manager" >
                                                        Product Manager
                                                </option>
                                                    <option value="UI/UX Designer" >
                                                        UI/UX Designer
                                                </option>
                                                    <option value="Desenvolvedor" >
                                                        Desenvolvedor
                                                </option>
                                                </select>
                                            </Col>

                                            <Button id="_form_1_submit" onClick={() => {
                                                setProgress(100)
                                            }} className="buttonForm btrigth" class="_submit" type="submit">
                                                Enviar
                                                </Button>
                                        </Row>
                                    </Form.Group>




                                    <div class="_button-wrapper _full_width" >

                                    </div>
                                </div>
                            </Form>

                        )}

                    </Col>

                </Row>
                <Col xs lg="2" className="logoForm">
                    <Image src={logo} />
                </Col>
            </Col>
            {/* <Col>
                    <Image src={logo} className="logoFormTetse" />
            </Col> */}
        </Container>
    )
}

export default TesteForm;